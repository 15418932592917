.container {
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
}

.container h1 {
  color: var(--primary);
  align-self: center;
  margin: 1rem 0;
}

.contactUsWrapper {
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 3rem 0;
  flex-direction: column;
}

.rowInfo {
  flex-direction: row;
  display: flex;
  align-items: space-around;
  margin: 2vh 0 5vh;
}

.columnInfo {
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 3rem;
}

.contactUsWrapper a:hover {
  color: #000;
}

.contactUsWrapper a {
  color: var(--primary);
  margin: 6px 0;
}

.contactUsWrapper h3,
.contactUsWrapper h4,
.contactUsWrapper h2 {
  color: #000;
  margin: 4px 0;
  text-align: center;
}

.contactUsWrapper h2 {
    text-decoration: underline;
}

.contactsContainerImg {
  width: 2rem;
  margin: 4px 10px;
}

.iframe {
  height: 40vh;
  width: 70vw;
  border: 0;
}

.btn {
  border: 0px solid var(--primary);
  border-radius: 20px;
  padding: 10px 18px;
  cursor: pointer;
  margin-top: 2rem;
}

.btn h3 {
  font-weight: 600;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

.bookNowBtnStyle {
  align-self: center;
  margin: 0px 0 0 0;
  /* background:var(--gradient); */
  border-radius: 30px;
}

.bookNowBtnStyle h3 {
  color: #000;
}

.footerStyle {
  background-color: var(--primary);
  /* background: linear-gradient(100deg, #fff69d, #bb8311 , #fff69d, #fff69d,#bb8311,#fff7b3,#e7c74c); */
}

.footerStyle a,
.footerStyle h3,
.footerStyle h2 {
  color: #fff;
}

.footerStyle a:hover {
  color: #000;
}

@media (max-width: 768px) {
  .container {
  }

  .iframe {
    height: 44vh;
    width: 100%;
  }

  .rowInfo {
    flex-direction: column;
    display: flex;
    align-items: space-around;
    margin: 2vh 0 5vh;
  }

  .columnInfo {
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem 0;
  }

  .contactsContainerImg {
    margin: 4px 10px 1rem;
  }
}
