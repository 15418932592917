.container {
  padding: 0 clamp(0rem, 6vw, 16rem);
  background-color: var(--primary);
}

.container h1 {
  text-align: center;
  padding: 2rem 0 0 0;
  font-weight: lighter;
  font-size: 2rem;
  margin: 0;
}

.cursiveText {
  font-family: Rumble_Brave, sans-serif;
  font-size: 2rem;
  margin: 0;
  padding: 0;
}

.feedbackRow {
  flex-direction: row;
  display: flex;
  justify-content: center;
  padding: 3rem 0;
  user-select: none;
}

.feedbackItem {
  width: clamp(14rem, 20%, 17rem);
  padding: 4vh 2vw;
  background-color: #fff;
  border-radius: 10px;
  margin: 0 10px;
}

.feedbackAvatarRow {
  display: flex;
  flex-direction: row;
  margin: 0 0 4vh 0;
  justify-content: space-around;
}

.customerNameColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  user-select: none; 
}

.feedbackItem h3 {
  font-size: 16px;
  color: #000;
  text-align: left;
  margin: 0;
}

.feedbackItem h4 {
  font-size: 14px;
  color: #000;
  text-align: left;
  margin: 0;
}

.feedbackImg {
  border-radius: 10rem;
  margin: 0 8px 0;
  display: flex;
  max-width: 4rem;
    max-height: 4rem;
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
    background-color: var(--primary);
  }

  .feedbackRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0;
  }

  .feedbackAvatarRow {
    margin: 0 0 6vh 0;
  }
  

  .feedbackItem {
    width: clamp(14rem, 70%, 18rem);
    padding: 4vh 6vw;
    background-color: #fff;
  }

  .feedbackItem h4 {
    font-size: 16px;
  }

  .feedbackImg {
    /* width: 6rem;
        height: 5rem; */
    max-width: 4rem;
    max-height: 6rem;
    margin: 0 4px 0;
  }
}
