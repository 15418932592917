.contacts {
  display: flex;
  background-color: var(--primary);
  flex-direction: row;
}

.contacts h1 {
  white-space: pre-wrap;
  text-align: left;
  margin: 0 0 20px;
  font-weight: bold;
  color: #fff;
}

.contacts a {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  margin: 0 0 6px 0;
  white-space: pre-wrap;
  text-align: center;
}

.contacts a:hover {
  transition: color 0.5s ease-in-out;
}

.contactsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* margin-top: 1rem; */
  padding: 2rem 0;
  justify-content: space-evenly;
  align-items: center;
}

.contactsContainerImg {
  width: 35px;
  height: 35px;
  margin: 2vh 0;
}

.marginLeftFBIcon {
  margin-left: 1rem;
}

.iframe {
  height: auto;
  width: 66%;
  border: 0;
}

.bookNowBtn {
  background-color: #fff;
  margin: 0 0 0;
  border-radius: 6px;
}

.bookNowBtn h3 {
  color: var(--primary);
  font-weight: lighter;
  margin: 0;
}

@media (max-width: 768px) {
  .contacts {
    flex-direction: column;
    padding: 0;
  }

  .contactsContainer {
  margin-top: 0;
  padding: 2rem 0;
  }

  .map {
    width: 100%;
    height: 40vh;
    margin-top: 1vh;
  }

  .iframe {
    height: 40vh;
    width: 100%;
  }
}
