.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.7);
}

.modal {
    position: fixed;
    top: 30%;
    width: 80%;
    max-width: 85%;
    max-height: 90%;
    z-index: 100;
    overflow: hidden;
    background-color: var(--background-color);
    border-radius: 5px;
    transform: translate(0, -30%);
    justify-content: center;
    align-items: center;
    align-self: center;
}

.header {
    background: var(--blue-strong-color);
    padding: 1rem;
}

.header h2 {
    margin: 0;
    color: #fff;
}

.content {
    padding: 1rem 1rem 0.2rem 1rem;
}

.modal h3 {
    color: var(--blue-strong-color);
    white-space: pre-wrap;
    line-height: 19px;
    padding: 0 1rem;
}

.actions {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
}

.actions h3 {
    margin-right: 3vw;
    color: var(--blue-strong-color);
}

.info {
    margin-left: 1rem;
    color: var(--blue-strong-color);
}

@media (min-width: 768px) {
    .modal {
        left: calc(50% - 20rem);
        width: 40rem;
    }
}

@media (max-width: 768px) {
    .modal {
        width: 80%;
        left: 10%;
    }
}

