.container {
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
}

.container h1 {
  color: var(--primary);
  align-self: center;
  margin: 1rem 0;
}

.bookNowBtnStyle {
  align-self: center;
  margin-bottom: 2vh;
}

.introTextContainer {
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 4vh 20vw;
}

.introTextContainer h1 {
  font-weight: bold;
  color: var(--primary);
  text-align: center;
  margin: 0 0 2vh 0;
}

.introTextContainer h3,
p {
  margin: 8px 0;
}

.greyIntroTextContainer {
  color: var(--grey-color);
  text-align: center;
  line-height: 27px;
}

@media (max-width: 768px) {
  .container {
  }

  .introTextContainer {
    margin: 2vh 6vw;
  }
}
