.wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--primary);
}

.container {
  display: flex;
  background-color: var(--primary);
  padding: 2rem 3rem;
  justify-content: space-around;
}

.container a,
.container h4,
.container h3,
.container h2,
a,
h4 {
  color: #fff;
}

.container a:hover{
  color: var(--secondary);
}

.container h2,
.container h3 {
  font-weight: bold;
  white-space: pre-wrap;
}

.address {
  margin: 0 5% 0 0;
}

.openingTimeWrapper {
  margin: 0 5% 0 0;
  display: flex;
  flex-direction: column;
}

.openingTimeContainer {
  display: flex;
  flex-direction: column;
}

.openingTimeContainer h3 {
  margin: 4px 0;
  font-weight: lighter;
  font-size: clamp(1vw, 1.2vw, 1.8vw);
  text-align: left;
}

.openingTime {
  display: flex;
  justify-content: space-between;
  max-width: 28vw;
  white-space: pre-wrap;
}

.contactContainer {
  flex-direction: column;
  margin: 0 5% 0 0;
}

.contactContainer img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  margin-top: 10px;
}

.columnInfo {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.fbPage {
  width: 30rem;
  height: 20rem;
}

.marginBottom {
  margin-bottom: 1rem;
}

.creditFooter {
  background-color: rgb(234, 231, 231);
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 14px 0;
}

.creditFooter a,
h4 {
  margin: 0;
  font-size: 14px;
  color: #000;
  font-weight: lighter;
}

.creditFooter a {
  text-decoration-line: underline;
}

.shopLogo{
  width: 11vw;
  min-width: 11rem;
  object-fit: contain;
  margin: 2vh;
  top:5rem;
  align-self: center;
}

.menuContainer {
  display: flex;
  flex-direction: column;
  flex: 0.5;
}

.menuContainer a.menuLink {
 text-decoration: underline;
 margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    padding: 0 3vw 1rem 3vw;
  }

  .container h2 {
    font-size: 8vw;
    margin: 1rem 0 0;
  }

  .container h4,
  a,
  h3 {
    font-size: 4vw;
  }

  .openingTimeWrapper {
    margin-top: 1vh;
    display: flex;
    margin: 0;
  }

  .address {
    margin: 0;
  }

  .openingTimeContainer {
    display: flex;
    flex-direction: column;
  }

  .openingTimeContainer h3 {
    font-size: clamp(1vw, 4vw, 7vw);
  }

  .contactContainer {
    margin-top: 3vh;
  }

  .openingTime {
    width: auto;
    max-width: 100%;
  }

  .creditFooter {
    background-color: rgb(234, 231, 231);
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 8px 3rem;
  }

  .creditFooter a,
  h4 {
    margin: 0;
    font-size: 12px;
    color: #000;
    font-weight: lighter;
    text-align: center;
  }

  .creditFooter a {
    text-decoration-line: underline;
  }

  .menuContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex:0;
    flex-wrap: wrap;
    padding: 0 10vw 0 0;
  }

  .menuContainer > * { /* Targeting the direct children of menuContainer */
  flex-basis: 48%; /* Adjusts width of each item to take up almost half of the row */
  box-sizing: border-box; /* Ensures padding and border are included in width */
  margin-top: 10px; /* Adds some space between rows */
}
}
