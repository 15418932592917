.introduction {
  margin: 0 0;
  background-color: #fff;
}

.rowWrapper {
  display: flex;
  flex-direction: row;
  padding: 7vh 5vw;
}

.introTextContainer {
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.introTextContainer h2 {
  color: var(--primary);
  text-align: center;
  font-weight: bold;
}

.introTextContainer p {
  color: var(--primary);
  text-align: center;
  line-height: 27px;
}

.imageRow {
  display: flex;
  flex-direction: row;
}

.tallImg {
  width: 20vw;
  height: 48vh;
  object-fit: cover;
  margin: 0 0.5rem 0 3rem;
}

.wideImgContainer {
  display: flex;
  flex-direction: column;
  height: 48vh;
  justify-content: space-between;
}

.wideImg {
  height: 20vh;
  width: 20vw;
  flex: 1;
  object-fit: cover;
}

.separator {
  height: 0.5rem;
}

@media (max-width: 768px) {
  .rowWrapper {
    flex-direction: column-reverse;
    padding: 0 0 2vh 0vw;
  }

  .tallImg {
    width: 49vw;
    height: 30vh;
    object-fit: cover;
    margin: 0 0.5rem 0 0;
  }

  .wideImgContainer {
    display: flex;
    flex-direction: column;
    height: 30vh;
    justify-content: space-between;
  }

  .wideImg {
    height: 12vh;
    width: 49vw;
  }

  .introTextContainer {
    padding: 0 6vw;
  }

  .introTextContainer h2 {
    text-align: center;
  }

  .introTextContainer p {
    text-align: center;
    line-height: 23px;
    font-size: 1rem;
  }
}
